import { AxiosResponse } from 'axios'
import { axiosInstance } from 'axiosInstance'

import { BOOKING_URLS } from 'config/urls'

import {
    IBooking,
    TWorkingDay,
    TTimeSlots,
    IBookingMultiLang,
    IManagement,
    CreateLocationPayload,
    ILocation,
    UpdateLocationPayload,
    TSlotResponse,
} from '../store/types'

export const getBookings = ({
    language,
    appId,
    page,
}: {
    appId: string
    page: string | number
    language: string
}): Promise<AxiosResponse<IBooking[]>> =>
    axiosInstance.get(
        // eslint-disable-next-line max-len
        `${appId}${BOOKING_URLS.booking}/language/${language}?pageNumber=${page}&pageSize=10`,
        // `${appId}${BOOKING_URLS.booking}?pageNumber=${page}&pageSize=10`,
    )

export const getBookingById = ({
    bookingId,
    appId,
}: {
    bookingId: string
    appId: string
}): Promise<AxiosResponse<IBooking>> =>
    axiosInstance.get(`${appId}${BOOKING_URLS.booking}/${bookingId}`)
// axiosInstance.get(`${appId}${BOOKING_URLS.booking}/${bookingId}`)

export const getBookingManagement = ({
    bookingId,
    appId,
    date,
}: {
    bookingId: string
    appId: string
    date?: string
}): Promise<AxiosResponse<IManagement>> =>
    axiosInstance.get(
        // eslint-disable-next-line max-len
        `${appId}${BOOKING_URLS.booking}/management/${bookingId}${
            date ? `?selectedDate=${date}` : ''
        }`,
    )

export const generateTimeSlots = ({
    appId,
    timeSlots,
}: {
    appId: string
    timeSlots: TWorkingDay[]
}): Promise<AxiosResponse<TTimeSlots[]>> =>
    axiosInstance.post(
        `${appId}${BOOKING_URLS.booking}/time-slots?duration=30`,
        timeSlots,
    )

export const createBooking = ({
    booking,
    appId,
}: {
    booking: IBookingMultiLang
    appId: string
}): Promise<AxiosResponse<IBooking>> =>
    // eslint-disable-next-line max-len
    axiosInstance.post(`${appId}${BOOKING_URLS.booking}`, booking)

export const editBooking = ({
    bookingId,
    booking,
    appId,
}: {
    bookingId: string
    booking: IBooking
    appId: string
}): Promise<AxiosResponse<IBooking>> =>
    axiosInstance.put(`${appId}${BOOKING_URLS.booking}/${bookingId}`, booking)

export const deleteBooking = ({
    bookingId,
    appId,
}: {
    appId: string
    bookingId: string
}): Promise<AxiosResponse<IBooking>> =>
    axiosInstance.delete(`${appId}${BOOKING_URLS.booking}/${bookingId}`)

export const updateSlotInfo = ({
    appId,
    bookingId,
    slotId,
    slot,
}: {
    appId: string
    bookingId: string
    slotId: string
    slot: any
}): Promise<AxiosResponse<IBooking>> =>
    axiosInstance.put(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        `${appId}${BOOKING_URLS.booking}/management/${bookingId}/${slotId}`,
        slot,
    )

export const bookSlot = ({
    appId,
    bookingId,
    slotId,
    name,
    email,
    phone,
    date,
    serviceId,
}: {
    appId: string
    bookingId: string
    slotId: string
    name: string
    email: string
    phone: string
    date: string
    serviceId?: string
}): Promise<AxiosResponse<TSlotResponse>> =>
    axiosInstance.post(
        // eslint-disable-next-line max-len
        `${appId}${
            BOOKING_URLS.booking
        }/management/${bookingId}/${slotId}?name=${name}&phoneNumber=${phone}&email=${email}&selectedDate=${date}${
            serviceId ? `&serviceId=${serviceId}` : ''
        }`,
    )

export const getBookingBill = ({
    appId,
    bookingId,
    timeSlotId,
}: {
    appId: string
    bookingId: string
    timeSlotId: string
}) =>
    axiosInstance.post(
        `${appId}${BOOKING_URLS.booking}/management/${bookingId}/${timeSlotId}/billing`,
    )

export const getLocations = ({
    appId,
    page,
}: {
    appId: string
    page?: number | string
}): Promise<AxiosResponse<ILocation[]>> =>
    axiosInstance.get(
        `${appId}${BOOKING_URLS.booking}${BOOKING_URLS.location}${
            page ? `?pageNumber=${page}` : ''
        }${page ? '&' : '?'}pageSize=50`,
    )

export const getLocationById = ({
    appId,
    id,
}: {
    appId: string
    id: string
}): Promise<AxiosResponse<ILocation>> =>
    axiosInstance.get(
        `${appId}${BOOKING_URLS.booking}${BOOKING_URLS.location}/${id}`,
    )
export const createLocation = ({
    appId,
    address,
    description,
    imgUrl,
    name,
}: CreateLocationPayload): Promise<AxiosResponse<ILocation>> =>
    axiosInstance.post(
        `${appId}${BOOKING_URLS.booking}${BOOKING_URLS.location}`,
        {
            name,
            address,
            description,
            photoUrl: imgUrl,
        },
    )

export const editLocation = ({
    appId,
    address,
    description,
    imgUrl,
    name,
    id,
}: UpdateLocationPayload): Promise<AxiosResponse<ILocation>> =>
    axiosInstance.put(
        `${appId}${BOOKING_URLS.booking}${BOOKING_URLS.location}/${id}`,
        {
            name,
            address,
            description,
            photoUrl: imgUrl,
        },
    )

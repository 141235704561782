import React from 'react'

import { Route, Redirect, useLocation } from 'react-router-dom'
import { useCookies } from 'react-cookie'

import { useUserAuthorized } from 'hooks/useUserAuthorized'
import { PATH_AUTH, PATH_WELCOME } from 'config/paths'

interface IProtectedRoute {
    // TODO
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    component: React.ComponentType<any>
    exact: boolean
    path: string
}

const PrivateRoute = ({
    component: Component,
    exact,
    path,
    ...rest
}: IProtectedRoute): React.ReactElement => {
    const currentUser = useUserAuthorized()
    const { search } = useLocation()

    const cookies = useCookies(['paid_plan', 'isTrialUsed'])[0]
    const query = new URLSearchParams(search)
    const sessionId = query.get('session_id')

    console.log(sessionId, 'sessionId')

    return (
        <Route
            {...rest}
            render={props => {
                if (!currentUser) {
                    return (
                        <Redirect
                            to={{
                                pathname: PATH_AUTH.login,
                            }}
                        />
                    )
                }
                if (
                    cookies.isTrialUsed === 'false' &&
                    !cookies.paid_plan &&
                    !sessionId
                ) {
                    return (
                        <Redirect
                            to={{
                                pathname: PATH_WELCOME.root,
                            }}
                        />
                    )
                }

                return <Component {...props} exact={exact} path={path} />
            }}
        />
    )
}

export default PrivateRoute
